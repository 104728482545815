import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ResponsiveLine } from "@nivo/line"
import { ResponsiveBar } from "@nivo/bar"
import Layout from "../../components/UI/layout"
import { generateFirstSecondOfMonthArray } from "../../utils/helpers"
import { customColors } from "../../../styles/colors"
import { usePortfolio } from "../../context/PortfolioContext"
import SectionHeader from "../../components/UI/elements/SectionHeader"

const PerformanceSite = () => {
  const rawData = useStaticQuery(graphql`
    {
      allSnapshots {
        nodes {
          mv
          timestamp
          nav {
            current
            eoly
          }
          holdings {
            asset {
              name
            }
            price {
              price
            }
          }
        }
      }
    }
  `)
  const { nav, timestamp, holdings, performance } = usePortfolio()
  const monthStartDates = generateFirstSecondOfMonthArray(new Date(2019, 6, 10))
  const customTheme = {
    textColor: "white",
    grid: {
      line: {
        stroke: "#333",
        strokeWidth: 1,
        strokeDasharray: 5,
      },
    },
    crosshair: {
      line: {
        stroke: "#ccc",
      },
    },
  }
  const data = [
    {
      id: "Fund",
      data: rawData.allSnapshots.nodes
        .filter(datapoint =>
          monthStartDates.includes(new Date(datapoint.timestamp).getTime())
        )
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .map(datapoint => {
          const day = new Date(new Date(datapoint.timestamp).getTime() - 1)
          return {
            x: `${day.getUTCFullYear()}-${
              day.getUTCMonth() + 1
            }-${day.getUTCDate()}`,
            y: datapoint.nav.current - 1,
          }
        }),
    },
    {
      id: "Bitcoin",
      data: rawData.allSnapshots.nodes
        .filter(datapoint =>
          monthStartDates.includes(new Date(datapoint.timestamp).getTime())
        )
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .map(datapoint => {
          const day = new Date(new Date(datapoint.timestamp).getTime() - 1)
          return {
            x: `${day.getUTCFullYear()}-${
              day.getUTCMonth() + 1
            }-${day.getUTCDate()}`,
            y:
              datapoint.holdings.find(item => item.asset.name === "Bitcoin")
                .price.price /
                9510 -
              1, // USD price for BTC on invesption date
          }
        }),
    },
  ]
  data[0].data.splice(0, 0, { x: "2019-6-30", y: 0 })
  data[1].data.splice(0, 0, { x: "2019-6-30", y: 0 })

  if (nav && timestamp) {
    data[0].data.push({ x: new Date(timestamp), y: nav.current - 1 })
    data[1].data.push({
      x: new Date(timestamp),
      y:
        holdings.find(item => item.asset.name === "Bitcoin").price.price /
          9510 -
        1,
    })
  }
  const barData = [
    {
      interval: "Today",
      Fund: performance ? performance.eold : 0,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        holdings.length > 1
          ? holdings.find(holding => holding.asset.name === "Bitcoin")
              .performance.eold
          : 0,
      BitcoinColor: "hsl(71, 70%, 50%)",
    },
    {
      interval: "This week",
      Fund: performance ? performance.eolw : 0,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        holdings.length > 1
          ? holdings.find(holding => holding.asset.name === "Bitcoin")
              .performance.eolw
          : 0,
      BitcoinColor: "hsl(71, 70%, 50%)",
    },
    {
      interval: "This month",
      Fund: performance ? performance.eolm : 0,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        holdings.length > 1
          ? holdings.find(holding => holding.asset.name === "Bitcoin")
              .performance.eolm
          : 0,
      BitcoinColor: "hsl(71, 70%, 50%)",
    },
    {
      interval: "This year",
      Fund: performance ? performance.eoly : 0,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        holdings.length > 1
          ? holdings.find(holding => holding.asset.name === "Bitcoin")
              .performance.eoly
          : 0,
      BitcoinColor: "hsl(71, 70%, 50%)",
    },
  ]
  const annualData = [
    {
      interval: "2019",
      Fund:
        rawData.allSnapshots.nodes.find(
          snapshot => new Date(snapshot.timestamp).getTime() === 1577836800000
        ).nav.current - 1,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        rawData.allSnapshots.nodes
          .find(
            snapshot => new Date(snapshot.timestamp).getTime() === 1577836800000
          )
          .holdings.find(item => item.asset.name === "Bitcoin").price.price /
          9510 -
        1,
      BitcoinColor: "hsl(71, 70%, 50%)",
    },
    {
      interval: "2020",
      Fund:
        rawData.allSnapshots.nodes.find(
          snapshot => new Date(snapshot.timestamp).getTime() === 1609459200000
        ).nav.eoly - 1,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        rawData.allSnapshots.nodes
          .find(
            snapshot => new Date(snapshot.timestamp).getTime() === 1609459200000
          )
          .holdings.find(item => item.asset.name === "Bitcoin").price.price /
          rawData.allSnapshots.nodes
            .find(
              snapshot =>
                new Date(snapshot.timestamp).getTime() === 1577836800000
            )
            .holdings.find(item => item.asset.name === "Bitcoin").price.price -
        1,
      BitcoinColor: "hsl(71, 70%, 50%)",
    },
    {
      interval: "2021",
      Fund:
        rawData.allSnapshots.nodes.find(
          snapshot => new Date(snapshot.timestamp).getTime() === 1640995200000
        ).nav.eoly - 1,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        rawData.allSnapshots.nodes
          .find(
            snapshot => new Date(snapshot.timestamp).getTime() === 1640995200000
          )
          .holdings.find(item => item.asset.name === "Bitcoin").price.price /
          rawData.allSnapshots.nodes
            .find(
              snapshot =>
                new Date(snapshot.timestamp).getTime() === 1609459200000
            )
            .holdings.find(item => item.asset.name === "Bitcoin").price.price -
        1,

      BitcoinColor: "hsl(71, 70%, 50%)",
    },
    {
      interval: "2022",
      Fund: performance ? performance.eoly : null,
      FundColor: "hsl(90, 70%, 50%)",
      Bitcoin:
        holdings.length > 1
          ? holdings.find(item => item.asset.name === "Bitcoin").price.price /
              rawData.allSnapshots.nodes
                .find(
                  snapshot =>
                    new Date(snapshot.timestamp).getTime() === 1640995200000
                )
                .holdings.find(item => item.asset.name === "Bitcoin").price
                .price -
            1
          : 0,

      BitcoinColor: "hsl(71, 70%, 50%)",
    },
  ]

  return (
    <Layout>
      <SectionHeader headerText="Performance since inception" />
      <div className="h-96">
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 20, bottom: 100, left: 60 }}
          theme={customTheme}
          xFormat="time:%Y-%m-%d"
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          axisBottom={{
            format: "%b %y",
            tickValues: "every 3 months",
            tickPadding: 18,
            tickSize: 14,
          }}
          axisLeft={{
            orient: "left",
            tickPadding: 12,
            tickSize: 7,
            format: ">-.0%",
          }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
          }}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            return (
              <div
                style={{
                  background: "white",
                  padding: "6px 12px",
                  border: "1px solid #ccc",
                }}
              >
                <div>{slice.points[0].data.xFormatted}</div>
                {slice.points.map(point => (
                  <div
                    key={point.id}
                    style={{
                      color: point.serieColor,
                      padding: "3px 0",
                    }}
                  >
                    <strong>{point.serieId}: </strong>
                    {(Number(point.data.yFormatted) * 100).toFixed(0)}%
                  </div>
                ))}
              </div>
            )
          }}
          pointLabelYOffset={2}
          enableGridX={true}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          useMesh={true}
          colors={customColors[1]}
          enableArea={true}
        />
      </div>
      <div className="w-full md:grid md:grid-cols-2 md:gap-8">
        <div className="flex flex-col">
          <div className="flex md:justify-start pb-4">
            <SectionHeader headerText="Recent performance" />
          </div>
          <div className="h-96 mb-4">
            <ResponsiveBar
              data={barData}
              theme={customTheme}
              keys={["Fund", "Bitcoin"]}
              indexBy="interval"
              margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
              padding={0.3}
              groupMode="grouped"
              valueScale={{
                type: "linear",
                max: Math.max(
                  ...barData.map(
                    item => Math.max(item.Fund, item.Bitcoin) * 1.1
                  ),
                  0.1
                ),
                min: Math.min(
                  ...barData.map(
                    item => Math.min(item.Fund, item.Bitcoin) * 1.1
                  ),
                  0
                ),
              }}
              indexScale={{ type: "band", round: true }}
              colors={customColors}
              valueFormat=">-.1%"
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: ">-.1%",
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: -40,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex md:justify-center pb-4">
            <SectionHeader headerText="Annual performance" />
          </div>
          <div className="h-96 mb-4">
            <ResponsiveBar
              data={annualData}
              theme={customTheme}
              keys={["Fund", "Bitcoin"]}
              indexBy="interval"
              margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
              padding={0.3}
              groupMode="grouped"
              valueScale={{
                type: "linear",
                max: Math.max(
                  ...annualData.map(
                    item => Math.max(item.Fund, item.Bitcoin) * 1.1
                  ),
                  0.1
                ),
                min: -1,
              }}
              indexScale={{ type: "band", round: true }}
              colors={customColors}
              valueFormat=">-.1%"
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: ">-.1%",
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: -40,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PerformanceSite
