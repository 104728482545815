export const generateFirstSecondOfMonthArray = startDate => {
  const getNextMonth = date =>
    new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0) + 86400000
    ).getTime()
  const result = []
  let d = startDate
  while (d < new Date()) {
    d = getNextMonth(new Date(d))
    result.push(d)
  }
  return result
}
