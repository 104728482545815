export const customColors = [
  "hsl(173, 80%, 40%)",
  "hsl(173, 80%, 35%)",
  "hsl(173, 80%, 30%)",
  "hsl(173, 80%, 25%)",
  "hsl(173, 80%, 20%)",
  "hsl(173, 80%, 15%)",
  "hsl(173, 80%, 10%)",
  "hsl(173, 80%, 5%)",
]
